import React, { useMemo, useRef } from 'react'
import { gql, useQuery } from '@apollo/client'
import styled from 'styled-components'
import 'twin.macro'
import { AdvancedTutoring, Button } from 'nzk-react-components'
import Loader from 'components/UI/Loader'
import Advert from '../components/Advert'
import StudentCard from '../components/StudentCard'

const Wrapper = styled.div`
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 15px;

  a {
    text-decoration: none;
    color: inherit;
  }
`

const Banner = styled.div`
  width: 100%;
  background-color: #ff0;
  border-bottom: 4px solid #d3c100;
  padding: 10px 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  white-space: pre;
  > .highlight {
    font-weight: bold;
    display: inline-block;
    background-color: black;
    color: #fff;
    padding: 2px 5px;
    border-radius: 4px;
  }

  > .note {
    font-size: 0.8rem;
  }
`

const Title = styled.div`
  padding-top: 30px;
  padding: 0px 15px;
  font-size: 30px;
  line-height: 36px;
  font-family: 'Rammetto One';
  margin-bottom: 28px;
  color: #fff;
  text-align: center;
  text-shadow: 0px 4px #afafaf, 0px 8px rgba(0,0,0,0.4);
  display: flex;
  align-items: center;
  justify-content: center;
`

const Students = styled.div`
  display: grid;
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 20px;
  grid-gap: 27px;
  width: 100%;
  grid-template-columns: repeat(auto-fit, 280px);
  justify-content: center;
`

const StarTutoringHome = () => {
  const wrapperRef = useRef<HTMLDivElement | null>(null)
  const { loading, data } = useQuery(gql`
    query getChildrenSubscribed {
      me {
        _id
        children {
          _id
          username
          name
          yearGroup
          avatar {
            url
          }
          starTutoring {
            _id
            willCancelAt
            subscriptionExpiresAt
            status
          }
        }
      }
    }
  `, {
    fetchPolicy: 'network-only'
  })

  const children = useMemo(() => {
    // return [...(data?.me.children || []), ...(data?.me.children || []), ...(data?.me.children || [])]
    return data?.me.children || []
  }, [data])

  const hasAtLeastOneSubscription = useMemo(() => {
    return children.filter(c => c.starTutoring?._id).length > 0
  }, [children])

  if (loading) {
    return <>
      <Title>Advanced Tutoring</Title>
      <Loader color='#fff' minHeight={300} />
      <Advert subscriber onGetStarted={() => {}} />
    </>
  }

  return <>
    { !hasAtLeastOneSubscription && <Banner>
      Use discount code <span className='highlight'>TUTOR50</span> to get <span className='highlight'>50% OFF</span> your first month. <span className='note'>(applicable only to Advanced Tutoring)</span>
    </Banner> }
    <Title tw='pt-3'>
      <Button theme='purple' size='regular' round>
        <AdvancedTutoring />
      </Button>
      <span tw='ml-3 pb-1'>Advanced Tutoring</span>
    </Title>
    { hasAtLeastOneSubscription && <Wrapper>
      <Students>
        {
          children.map(c => <StudentCard key={c._id} user={c} />)
        }
      </Students>
    </Wrapper> }
    <Advert subscriber={hasAtLeastOneSubscription} onGetStarted={() => {
      if (typeof window !== 'undefined' && wrapperRef.current)
      window.scrollTo({
        top: wrapperRef.current.offsetTop,
        behavior: 'smooth',
      })
    }} />
    { !hasAtLeastOneSubscription && <Wrapper tw='pb-40' ref={wrapperRef}>
      <Students>
        {
          children.map(c => <StudentCard key={c._id} user={c} />)
        }
      </Students>
    </Wrapper> }
  </>
}

export default StarTutoringHome
