import StarTutoringHome from 'components/pages/StarTutoring/pages/StarTutoringHome'
import React from 'react'
import Layout from '../../layouts/layout'

export default () => {
  if (typeof window === "undefined") return null
  return <Layout>
    <StarTutoringHome />
  </Layout>
}
