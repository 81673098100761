import React from 'react'
import useConfirmModal from 'hooks/useConfirmModal'
import { gql, useMutation } from '@apollo/client'

const useCancel = (): [
  (zookeeper: any) => any,
  { loading: boolean }
] => {
  const { confirm: confirmCancel } = useConfirmModal(
    <>
      <h2>Cancel</h2>
      <p>Are you sure you want to cancel subscription for this child?</p>
    </>
  )

  const [cancelMutation, { loading }] = useMutation(gql`
    mutation cancelStarTutoring($zookeeperId: String!) {
      starTutoring_cancel(zookeeperId: $zookeeperId) {
        _id
        starTutoring {
          _id
          status
          subscriptionExpiresAt
          willCancelAt
        }
      }
    }
  `)

  const cancel = (zookeeper: any) => {
    if (loading || !zookeeper?._id) return
    confirmCancel(
      () => cancelMutation({ variables: { zookeeperId: zookeeper._id }}),
      () => {}
    )
  }

  return [cancel, { loading }]
}

export default useCancel
