import { Button, Checkmark, Close, IconButton } from 'nzk-react-components'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import Div100vh from 'react-div-100vh'
import styled from 'styled-components'

const Overlay = styled(Div100vh)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  background-color: rgba(0,0,0,0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999999;
`

const Wrapper = styled.div`
  background-color: #341644;
  color: #fff;
  padding-bottom: 20px;
  > h1, h2, h3 {
    font-family: 'Rammetto One';
    text-shadow: 0 3px 0 #afafaf, 0 6px 0 rgba(0,0,0,0.4);
  }
  @media (min-width: 500px) {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 -6px 0 #531D75, 0 6px 0 #1C042B, 0 12px 0 rgba(0,0,0,0.4);
    border-radius: 3px;
    min-width: 360px;
    min-height: 220px;
    border-radius: 20px;
    margin: 0 20px;
    
  }
  @media (max-width: 499px) {
    position: fixed;
    bottom: 0;
    left: 0;
    transform: translateY(100%);
    box-shadow: 0 -6px 0 #531D75, 0 6px 0 #1C042B, 0 12px 0 rgba(0,0,0,0.4);
    animation: animate-in-confirm 300ms ease-in-out forwards;
    @keyframes animate-in-confirm {
      from {
        transform: translateY(100%);
      }
      to {
        transform: translateY(0%);
      }
    }
  }

`

const Content = styled.div`
  padding: 20px;
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  line-height: 30px;
`

const Actions = styled.div`
  display: flex;
  width: 100%;
  margin-top: auto;
  justify-content: center;
  > * { margin-right: 20px; }
  > :last-child { margin-right: 0; }
`

const Quit = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(40%, -40%);
  @media (max-width: 499px) {
    transform: translate(0%, -40%);
  }
`

const ConfirmModal = ({ message, onConfirm, onCancel }) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden'
    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [])
  return <Overlay onClick={onCancel}>
  <Wrapper onClick={(e) => e.stopPropagation()}>
    <Quit onClick={onCancel}>
      <Button theme='red' size='small' onClick={onCancel} round>
        <Close />
      </Button>
    </Quit>
    <Content>{ message }</Content>
    <Actions>
      <IconButton theme='red' size='small' onClick={onCancel} icon={<Close />}>Cancel</IconButton>
      <IconButton theme='confirm' size='small' onClick={onConfirm} icon={<Checkmark />}>
        Confirm
      </IconButton>
    </Actions>
  </Wrapper>
  </Overlay>
}

ConfirmModal.propTypes = {
  message: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string
  ]),
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func
}

ConfirmModal.defaultProps = {
  message: 'Are you sure?',
  onConfirm: () => {},
  onCancel: () => {}
}

export default ConfirmModal
