import React from 'react'
import ConfirmModal from '../components/UI/ConfirmModal'
import { ModalState } from '../state/ModalState'

const useConfirmModal = (content) => { 
  const { displayModal, dismissModal } = ModalState.useContainer()

  const confirm = (onConfirm, onCancel) => {
    displayModal(<ConfirmModal
      onConfirm={() => {
        onConfirm()
        dismissModal()
      }}
      onCancel={() => {
        onCancel()
        dismissModal()
      }}
      message={content}
    />)
  }

  return { confirm }
}

export default useConfirmModal