import { ApolloError, gql, useMutation } from '@apollo/client';

const useUncancel = (): [
  (zookeeperId: string) => any,
  { loading: boolean, error?: ApolloError, data: any }
] => {
  const [uncancelMutation, { loading, error, data }] = useMutation(gql`
    mutation uncancelStarTutoring($zookeeperId: String!) {
      starTutoring_uncancel(zookeeperId: $zookeeperId) {
        _id
        starTutoring {
          _id
          status
          subscriptionExpiresAt
          willCancelAt
        }
      }
    }
  `)

  const uncancel = async (zookeeperId: string) => {
    await uncancelMutation({ variables: { zookeeperId }})
  }

  return [uncancel, { loading, error, data }]
}

export default useUncancel
