
import { ArrowDown, ArrowUp, Button } from 'nzk-react-components'
import React, { useState } from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'

const QUESTIONS = [
  {
    question: 'How does Advanced Tutoring differ from regular tutor feedback on nightzookeeper.com?',
    answer: 'With Advanced Tutoring, your child is assigned a tutor and sent personalised writing assignments based on skills you’d like them to improve. After completing the assignment, they  receive in-depth, annotated feedback on their assignment from their tutor, that helps your child with their organisation of ideas & text structure, vocabulary choice, sentence structure, grammar, punctuation, and spelling.',
  },
  {
    question: 'What kind of assignments do children complete?',
    answer: 'Tutors will send assignments designed to improve all areas of writing, as well the skills you have identified need improving. Assignment topics will cover report writing, persuasive writing, story writing, poetry and focus on developing composition & structure, spelling, grammar, and word choice.'
  },
  {
    question: 'When are assignments sent and will I get notified?',
    answer: 'Assignments will be sent once a week but can be completed at any time, even after turning off Advanced Tutoring. The first assignment is sent as soon as you start Advanced Tutoring, and from then every week at the same time until turned off. You’ll receive an email when the assignment is sent, when the tutor has left feedback and when your child completes their edits.',
  },
  {
    question: 'Can I contact the tutor and change the type of assignments my child receives?',
    answer: 'When you start Advanced Tutoring you can submit a note to your tutor to highlight the skills you would like your child to focus on. This can be updated at anytime in your dashboard and your tutor will be notified.',
  },
  {
    question: 'Can I add Advanced Tutoring for an individual child?',
    answer: "You are able to add Advanced Tutoring to a specific child in your account. This way, you can pause or re-start Advanced Tutoring depending on your child's needs at different times."
  }
]

const Wrapper = styled.div``

const Questions = styled.div`
${tw`max-w-2xl m-auto`};
`

const Question = styled.div<{ expanded: boolean }>`
  ${tw`mb-6`}
  > .question {
    display: flex;
    align-items: flex-start;
    margin-bottom: 15px;
    ${tw`font-size[24px] line-height[30px] cursor-pointer font-bold`}
  }
  > .answer {
    ${tw`mt-1 font-size[18px] line-height[35px]`};
    padding-left: 42px;
    display: ${props => props.expanded ? 'block' : 'none'};
  }
`

const FAQ = () => {
  const [expanded, setExpanded] = useState<number[]>([])

  const toggleExpanded = (i: number) => {
    setExpanded(expanded => {
      if (expanded.indexOf(i) >= 0) {
        return expanded.filter(d => d !== i)
      }
      return [...expanded, i]
    })
  }

  return <Wrapper>
    <Questions>
      {
        QUESTIONS.map((q, i) => <Question expanded={expanded.indexOf(i) >= 0}>
          <div className='question' onClick={() => toggleExpanded(i)} onKeyPress={(e) => e.key === 'Enter' && toggleExpanded(i)} role='button' tabIndex={0}>
            <Button theme='primary' size='x-small' round>
              { expanded.indexOf(i) >= 0 ? <ArrowUp /> : <ArrowDown /> }
            </Button>
            <div tw='ml-3'>{q.question}</div>
          </div>
          <div className='answer'>{q.answer}</div>
        </Question>)
      }
    </Questions>
  </Wrapper>
}

export default FAQ
