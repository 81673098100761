export const getAgeFromYearGroup = yearGroup => {
  switch (yearGroup) {
    case 0:
      return "5"
    case 1:
      return "6"
    case 2:
      return "7"
    case 3:
      return "8"
    case 4:
      return "9"
    case 5:
      return "10"
    case 6:
      return "11"
    default:
      return "12+"
  }
}

export const findCountry = (countryCode) => {
  let Countries = require('../../../../countries.json')
  var filteredObj = Countries.find(function(item, i) {
    if(item.value == countryCode) {
      return item.label
    }
  })
  if(filteredObj != undefined) return filteredObj.label
  return countryCode
}