import React from 'react'
import 'twin.macro'
import styled from 'styled-components'
import { Button } from 'nzk-react-components'
import RatioBox from '../../../../../UI/RatioBox'
import Video from '../../Video'

const Wrapper = styled.div`
  font-family: 'Libre Baskerville';
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  .video {
    border-radius: 20px;
    overflow: hidden;
  }
  @media (max-width: 990px) {
    max-width: 600px;
    width: 100%;
    margin: 0 auto;
    grid-template-columns: 1fr;
  }
`

const Title = styled.h2`
  margin-bottom: 20px;
  line-height: 50px;
  font-size: 40px;
  font-weight: 400;
`

const Hero = (props: { subscriber: boolean, onGetStarted: () => void }) => {
  return <Wrapper>
    <div>
      { props.subscriber
        ? <Title>Add another child to Advanced Tutoring</Title>
        : <Title>Start a free trial of Advanced Tutoring</Title>
      }
      <p tw='line-height[30px] font-size[16px] mb-5'>Available exclusively for nightzookeeper.com customers</p>
      { !props.subscriber && props.onGetStarted && <Button size='large' theme='confirm' onClick={props.onGetStarted}>Get started</Button> }
    </div>
    <div className='video'>
      <RatioBox ratio='16:9'>
        <Video src='https://vimeo.com/758038648' />
      </RatioBox>
    </div>
  </Wrapper>
}

export default Hero
