import { getAgeFromYearGroup } from 'components/pages/StudentPage/helpers'
import { navigate } from 'gatsby'
import { Avatar, Button, IconButton, Progress } from 'nzk-react-components'
import React, { useMemo } from  'react'
import { FormattedDate } from 'react-intl'
import styled from 'styled-components'
import useCancel from '../hooks/useCancel'
import useUncancel from '../hooks/useUncancel'

interface IProps {
  user: {
    _id: string
    username: string
    name?: string
    yearGroup: number
    avatar: {
      url: string
    }
    starTutoring?: {
      _id: string
      willCancelAt: string
      subscriptionExpiresAt: string
      status: string
    }
  }
}

const Wrapper = styled.div`
  background-color: #341644;
  border-radius: 20px;
  box-shadow: 0 5px 0 #1C042B, 0 8px 0 rgba(0,0,0,0.4), 0 -5px 0 #531D75;
  padding: 30px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  > .informations {
    display: flex;
    font-family: 'Rammetto One';
    font-size: 12px;
    line-height: 20px;

    > :first-child {
      margin-right: 22px;
    }
    .subscription-informations {
      display: flex;
      font-size: 12px;
      font-family: 'Libre Baskerville';
    }
  }
  
  > .actions {
    display: flex;
    justify-content: center;
    margin-top: 15px;
  }
`

const StudentCard = (props: IProps) => {
  const [cancel, { loading: cancelling }] = useCancel()
  const [uncancel, { loading: uncancelling }] = useUncancel()

  const button = useMemo(() => {
    if (props.user.starTutoring?.status !== 'cancelled') {
      if (cancelling || uncancelling) {
        return <Button size='x-small' theme='confirm' disabled>Loading...</Button>
      }
      if (props.user.starTutoring?.willCancelAt && new Date(props.user.starTutoring.willCancelAt) > new Date()) {
        return <Button size='x-small' theme='orange' onClick={() => uncancel(props.user._id)}>Enable Auto Renewal</Button>
      }
      if (!props.user.starTutoring) {
        return <Button size='x-small' theme='primary' onClick={() => navigate(`/advanced-tutoring/${props.user.username}/subscribe`)}>Select Child</Button>
      }
      return <Button size='x-small' theme='red' onClick={() => cancel(props.user)}>Cancel</Button>
    }
    if (props.user.starTutoring?.status === 'cancelled') {
      return <Button size='x-small' theme='primary' onClick={() => navigate(`/advanced-tutoring/${props.user.username}/subscribe`)}>Re-Subscribe</Button>
    }
  }, [uncancelling, cancelling, props])

  return <Wrapper onClick={() => {
    if (!props.user.starTutoring) {
      navigate(`/advanced-tutoring/${props.user.username}/subscribe`)
    }
  }}>
    <div className='informations'>
      <Avatar user={{ ...props.user, type: 'student' }} size='60px' />
      <div>
        <div>{props.user.name || props.user.username}</div>
        <div>Age: {getAgeFromYearGroup(props.user.yearGroup)}</div>
        <div className='subscription-informations'>
          { props.user.starTutoring?.willCancelAt && props.user.starTutoring.status !== 'cancelled' && <div>Set to <b>cancel</b> <FormattedDate value={props.user.starTutoring.willCancelAt} /></div> }
          { props.user.starTutoring?.status === 'cancelled' && <div>Canceled <FormattedDate value={props.user.starTutoring?.subscriptionExpiresAt} /></div> }
          { !props.user.starTutoring?.willCancelAt
            && props.user.starTutoring?.subscriptionExpiresAt
            && props.user.starTutoring?.status !== 'cancelled' && <div><b>Renews</b> <FormattedDate value={props.user.starTutoring.subscriptionExpiresAt} /></div> }
        </div>
      </div>
    </div>
    { props.user.starTutoring
      && props.user.starTutoring?.status
      && <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'center' }}>
      <IconButton size='x-small' theme='purple' icon={<Progress />} onClick={() => navigate(`/advanced-tutoring/${props.user.username}`)}>
        View Progress
      </IconButton>
    </div> }
    
    <div className='actions'>
      {button}
    </div>
  </Wrapper>
}

export default StudentCard
