import { AdvancedTutoring, Button, Target } from 'nzk-react-components'
import React from 'react'
import 'twin.macro'
import styled from 'styled-components'
import Hero from './components/Hero'
import FAQ from './components/FAQ'

const Wrapper = styled.div`
  color: #fff;
  padding-bottom: 30px;
  .hero-wrapper {
    max-width: 1180px;
    margin: 0 auto;
    padding: 30px 40px 0 40px;
  }
`

const Section = styled.div`
  padding: 33px 40px;
  .section--inner {
    max-width: 1100px;
    margin: 0 auto;
    h3 {
      margin-bottom: 1em;
    }
  }
  .title {
    font-family: 'Rammetto One', cursive;
    text-align: center;
    font-size: 16px;
  }

  .features {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 50px;
    @media (max-width: 990px) {
      grid-template-columns: 1fr;
      > * {
        max-width: 520px;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  .features--how-it-works {
    list-style-type: none;
    padding: 0;
    > li {
      display: flex;
      align-items: center;
      font-size: 16px;
      line-height: 30px;
      > :first-child {
        margin-right: 8px;
      }
      .big-number {
        font-size: 25px;
        text-shadow: 0px 3px #afafaf, 0px 6px rgba(0, 0, 0, 0.3);
        padding-bottom: 3px;
        padding-right: 1px;
      }
    }
  }

  .interactive-assignments {
    ul {
      padding-left: 10px;
      list-style-type: '- ';
    }
    font-size: 1em;
    line-height: 2;
  }
`

const Feature = styled.div`
  position: relative;
  width: 100%;
  margin-top: 30px;
  background-color: #341644;
  padding: 100px 30px 30px 30px;
  border-radius: 30px;
  font-size: 16px;
  line-height: 30px;
  text-align: center;
  > :first-child {
    position: absolute;
    top: 0%;
    left: 50%;
    transform: translate(-50%, -20%);
    display: flex;
    justify-content: center;
  }
  > .features--content {
  }

`

export default (props: { subscriber: boolean, onGetStarted: () => void }) => {
  return <Wrapper>
    <div className='hero-wrapper'>
      <Hero subscriber={props.subscriber} onGetStarted={props.onGetStarted} />
    </div>
    <Section>
      <div className='section--inner'>
        <div className='title'>
          <h3>The Benefits</h3>
        </div>
        <div className='features'>
          <Feature>
            <Button size='x-large' theme='red' round>
              <Target />
            </Button>
            <div className='features--content'>
              <p>In-depth, annotated, and personalised feedback on special writing assignments from a dedicated tutor.</p>
            </div>
          </Feature>
          <Feature>
            <Button size='x-large' theme='purple' round>
              <AdvancedTutoring />
            </Button>
            <div className='features--content'>
              <p>A flexible tutoring add-on to nightzookeeper.com, that can be turned on whenever your child needs extra writing practice.</p>
            </div>
          </Feature>
        </div>
      </div>
    </Section>
    <Section style={{ backgroundColor: '#341644' }}>
      <div className='section--inner'>
        <div className='title'>
          <h3>How it works</h3>
        </div>
        <div className='features--how-it-works' tw='grid xl:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 grid-gap[45px] line-height[30px] font-size[16px]'>
          <div className='feature--how-it-work'>
            <img src='/images/advanced-tutoring/stressed-kid.jpg' alt='Child' tw='w-full object-contain border-radius[16px]' />
            <p>Tell us where your child needs extra help with their writing and turn on Advanced Tutoring.</p>
          </div>
          <div className='feature--how-it-work'>
            <img src='/images/advanced-tutoring/working-tutor.jpg' alt='Tutor' tw='w-full object-contain border-radius[16px]' />
            <p>A dedicated tutor will send a special writing assignment to your child’s nightzookeeper.com account.</p>
          </div>
          <div className='feature--how-it-work'>
            <img src='/images/advanced-tutoring/happy-girl.jpg' alt='Child' tw='w-full object-contain border-radius[16px]' />
            <p>Once your child completes the assignment, they’ll receive in-depth, annotated feedback from their tutor within 24 hours of submission <span tw='text-xs'>(weekends excluded)</span>.</p>
          </div>
        </div>
      </div>
    </Section>
    { !props.subscriber && props.onGetStarted && <div tw='mt-[-35px] flex justify-center'>
      <Button size='large' theme='confirm' onClick={props.onGetStarted}>Get started</Button>
    </div> }
    <Section>
      <div className='section--inner'>
        <div className='title'>
          <h3>FAQ's</h3>
        </div>
        <div tw='mt-10'>
          <FAQ />
        </div>
      </div>
    </Section>
  </Wrapper>
}
